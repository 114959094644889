import '../node_modules/alpinejs';
import * as Rellax from '../node_modules/rellax';
import '../node_modules/bootstrap';
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

SwiperCore.use([Pagination, Autoplay]);

// @ts-ignore
window.Swiper = Swiper;
new Rellax('.rellax');
